import React, { useState, useEffect } from "react";
import { Modal, Card, Nav, Tab } from "react-bootstrap";
import {
  AdministratifSection,
  AlamatSection,
  BankSection,
  InformasiUmumSection,
  KontakSection,
} from "../Section";
import { LaporanCustomerApi } from "api";
import { Alert, DataStatus } from "components";

export const ModalDetail = ({ setModalConfig, modalConfig }) => {
  const [dataDetail, setDataDetail] = useState({});
  const [loadingModal, setLoadingModal] = useState(false);
  const [alertModalConfig, setAlertModalConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setLoadingModal(true);
    LaporanCustomerApi.single({ id_customer: modalConfig.id })
      .then(({ data }) => setDataDetail(data.data ?? {}))
      .catch((err) =>
        setAlertModalConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => setLoadingModal(false));
  };
  useEffect(() => {
    getInitialData();
    return () => {
      setLoadingModal(false);
      setAlertModalConfig({ show: false, variant: "primary", text: "" });
    };
  }, []);

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ ...modalConfig, show: false })}
      size="lg"
    >
      <Modal.Header closeButton>Detail Customer</Modal.Header>
      <Modal.Body>
        <Alert
          variant={alertModalConfig?.variant}
          show={alertModalConfig?.show}
          showCloseButton
          onClose={() =>
            setAlertModalConfig({ ...alertModalConfig, show: false })
          }
        />
        {loadingModal ? (
          <DataStatus loading text="Memuat Data..." />
        ) : (
          <Card className="p-0 m-0">
            <Tab.Container defaultActiveKey="informasiUmum">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="informasiUmum">Informasi Umum</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="administratif">Administratif</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="kontak">Kontak</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="alamat">Alamat</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="bank">Bank</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Tab.Content>
                <Tab.Pane eventKey="informasiUmum" className="p-4">
                  <InformasiUmumSection data={dataDetail} />
                </Tab.Pane>
                <Tab.Pane eventKey="administratif" className="p-4">
                  <AdministratifSection data={dataDetail} />
                </Tab.Pane>
                <Tab.Pane eventKey="kontak" className="p-4">
                  <KontakSection data={dataDetail} />
                </Tab.Pane>
                <Tab.Pane eventKey="alamat" className="p-4">
                  <AlamatSection data={dataDetail} />
                </Tab.Pane>
                <Tab.Pane eventKey="bank" className="p-4">
                  <BankSection data={dataDetail} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card>
        )}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
