import { InfoItemVertical } from "@bhawanadevteam/react-core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DateConvert, RupiahConvert } from "utilities";

export const InformasiUmumSection = ({ data }) => {
  return (
    <Row>
      <Col>
        <InfoItemVertical
          label="Tgl. Customer Terdaftar"
          text={
            DateConvert(new Date(data?.tgl_customer_terdaftar))?.detail ?? "-"
          }
        />
        <InfoItemVertical
          label="Jenis Customer"
          text={data?.nama_jenis_customer ?? "-"}
        />
        <InfoItemVertical
          label="Kode Customer"
          text={data?.kode_customer ?? "-"}
        />
        <InfoItemVertical
          label="Nama Customer"
          text={data?.nama_customer ?? "-"}
        />
        <InfoItemVertical
          label="Kredit Limit"
          text={
            RupiahConvert(parseFloat(data?.kredit_limit ?? 0).toString())
              .getWithComa
          }
        />
      </Col>
      <Col>
        <InfoItemVertical label="Keterangan" text={data?.keterangan ?? "-"} />
      </Col>
    </Row>
  );
};
