import Services from "../../../services";

class JenisCustomerApi {
  get() {
    return Services.get("/crm/jeniscustomer");
  }

  getPage(page, dataLength, key) {
    return Services.get(`/crm/jeniscustomer/page/?per_page=${dataLength}&page=${page}&q=${key}`);
  }

  single(params) {
    return Services.get('/crm/jeniscustomer/single', { params })
  }

  getKode() {
    return Services.get("/crm/jeniscustomer/no_baru");
  }

  search(key) {
    return Services.get("/crm/jeniscustomer/page/?per_page=10&page=1&q=" + key);
  }

  show(value) {
    return Services.put("/crm/jeniscustomer/show", value);
  }

  hide(value) {
    return Services.put("/crm/jeniscustomer/hide", value);
  }

  create(value) {
    return Services.post("/crm/jeniscustomer", value);
  }

  update(value) {
    return Services.put("/crm/jeniscustomer", value);
  }

  delete(value) {
    return Services.post("/crm/jeniscustomer/delete", value);
  }
}

export default new JenisCustomerApi();
