import Services from "../../../services";

class LaporanCustomerApi {
  dorpdownJenisCustomer() {
    return Services.get("/crm/jeniscustomer");
  }

  getPage(params) {
    return Services.get(`/crm/mastercustomer/page_laporan`, { params });
  }

  single(params) {
    return Services.get("/crm/mastercustomer/single", { params });
  }

  export(params) {
    return Services.get("/crm/mastercustomer/export", { params });
  }
}

export default new LaporanCustomerApi();
