import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import _ from "lodash";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  InputSearch,
  FilterButton,
  TBody,
  Th,
  ThFixed,
  Tr,
  Td,
  TdFixed,
  THead,
  Pagination,
  ReadButton,
} from "components";

import { LaporanCustomerApi } from "api";

import Axios from "axios";
import { ExportButton, ModalDetail, ModalFilter } from "./Comp";
import FileSaver from "file-saver";
import { DateConvert, PageNumber } from "utilities";

export const LaporanCustomer = ({ setNavbarTitle }) => {
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataSalesOrder, setDataSalesOrder] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [modalDetailConfig, setModalDetailConfig] = useState({
    show: false,
    id: undefined,
  });
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.surat_jalan?.filter?.active,
      bulan:
        location?.state?.surat_jalan?.filter?.bulan ??
        DateConvert(new Date()).defaultMonth,
      tahun:
        location?.state?.surat_jalan?.filter?.tahun ??
        DateConvert(new Date()).defaultYear,
      id_jenis_customer:
        location?.state?.surat_jalan?.filter?.id_jenis_customer,
    },
    pagination: {
      page: location?.state?.surat_jalan?.filter?.page ?? "1",
      dataLength: location?.state?.surat_jalan?.filter?.dataLength ?? "10",
      totalPage: location?.state?.surat_jalan?.filter?.totalPage ?? "1",
      dataCount: location?.state?.surat_jalan?.filter?.dataCount ?? "0",
    },
  });
  const [dropdownFilter, setDropdownFilter] = useState({
    jenisCustomer: [],
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [loadingExport, setLoadingExport] = useState(false);
  const monthOptions = [
    { label: "Januari", value: "01" },
    { label: "Februari", value: "02" },
    { label: "Maret", value: "03" },
    { label: "April", value: "04" },
    { label: "Mei", value: "05" },
    { label: "Juni", value: "06" },
    { label: "Juli", value: "07" },
    { label: "Agustus", value: "08" },
    { label: "September", value: "09" },
    { label: "Oktober", value: "10" },
    { label: "November", value: "11" },
    { label: "Desember", value: "12" },
  ];

  const getInitialData = () => {
    setIsPageLoading(true);

    LaporanCustomerApi.getPage({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      bulan: dataFilter?.filter?.bulan,
      tahun: dataFilter?.filter?.tahun,
      id_jenis_customer: dataFilter?.filter?.id_jenis_customer,
    })
      .then((data) => {
        setDataSalesOrder(data?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const getDataDropdown = () => {
    Axios.all([LaporanCustomerApi.dorpdownJenisCustomer()])
      .then(
        Axios.spread((cuss) => {
          const mappingCustomer = (data) =>
            data.map((val) => ({
              label: val.nama_jenis_customer,
              value: val.id_jenis_customer,
            }));

          setDropdownFilter({
            jenisCustomer: [
              { label: "Semua", value: undefined },
              ...mappingCustomer(cuss?.data?.data ?? []),
            ],
          });
        })
      )
      .finally(() => {});
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      },
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  const exportData = (tipe) => {
    setLoadingExport(true);

    LaporanCustomerApi.export({
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      bulan: dataFilter?.filter?.bulan,
      tahun: dataFilter?.filter?.tahun,
      id_jenis_customer: dataFilter?.filter?.id_jenis_customer,
      active: undefined,
      tipe,
    })
      .then((res) => {
        const data = res.data.data;

        FileSaver.saveAs(data, data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        });
      })
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    setNavbarTitle("LAPORAN CUSTOMER");
    getInitialData();

    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.bulan,
    dataFilter?.filter?.tahun,
    dataFilter?.filter?.id_jenis_customer,
  ]);

  useEffect(() => {
    getDataDropdown();

    return () => {};
  }, []);

  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Tgl. Customer Terdaftar</ThFixed>
            <ThFixed>Kode</ThFixed>
            <Th>Nama Customer</Th>
            <Th>Jenis Customer</Th>
          </Tr>
        </THead>
        <TBody>
          {dataSalesOrder.length > 0 ? (
            dataSalesOrder.map((val, index) => (
              <Tr key={index}>
                <TdFixed>
                  {PageNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>

                <TdFixed>
                  <div className="d-flex justify-content-center">
                    <ReadButton
                      onClick={() =>
                        setModalDetailConfig({
                          ...modalDetailConfig,
                          show: true,
                          id: val?.id_customer,
                        })
                      }
                    />
                  </div>
                </TdFixed>
                <TdFixed>
                  {DateConvert(new Date(val.tgl_customer_terdaftar)).custom}
                </TdFixed>
                <TdFixed>{val.kode_customer}</TdFixed>
                <Td>{val.nama_customer}</Td>
                <Td>{val.nama_jenis_customer}</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="6">
                <DataStatus text="Data Tidak Ada" />
              </Td>
            </Tr>
          )}
        </TBody>
      </CRUDLayout.Table>
      {dataFilter?.pagination?.dataCount > 0 && (
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={
            dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength -
            dataFilter?.pagination?.dataLength +
            1
          }
          dataPage={
            dataFilter?.pagination?.dataCount <
            dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              },
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              },
            })
          }
        />
      )}
    </>
  );

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              value={searchConfig.key}
              onChange={onInputSearchChange}
            />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
              className="mr-2"
            />

            <ExportButton
              loading={loadingExport}
              // disabled={!dataFilter?.filter?.active}
            >
              <Dropdown.Item onClick={() => exportData("excel")}>
                Excel (.xlsx)
              </Dropdown.Item>
              <Dropdown.Item onClick={() => exportData("pdf")}>
                PDF (.pdf)
              </Dropdown.Item>
            </ExportButton>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <>
          <b>
            List Data Customer Yang Terdaftar Pada{" "}
            {
              monthOptions?.find(
                (val) => val?.value === dataFilter?.filter?.bulan
              )?.label
            }{" "}
            {dataFilter?.filter?.tahun}
          </b>
          <Table />
        </>
      )}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
          dropdown={dropdownFilter}
        />
      )}
      {modalDetailConfig?.show && (
        <ModalDetail
          setModalConfig={setModalDetailConfig}
          modalConfig={modalDetailConfig}
        />
      )}
    </CRUDLayout>
  );
};
